import React, { useState, useEffect } from "react";
import { alpha, styled } from '@mui/material/styles';
import { IconButton , Grid, Box, Switch, Button, TextField, Typography, FormControl, InputLabel, InputAdornment, Tooltip, Slide, Dialog, DialogTitle, DialogActions, DialogContent, Drawer, Select, MenuItem, Autocomplete } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import { gridClasses, DataGridPro, GridActionsCellItem, GridToolbarQuickFilter, useGridApiRef,useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro';
import OnneHelper from "../OnneHelper/OnneHelper";
import OnneFormula from "../OnneFormula/OnneFormula";
import toolsMappingApi from "../../tools/toolsMappingApi";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDmFieldsData } from "../../features/dmFieldsDataSlice";
import useToolsNotifications from "../../tools/ToolsNotifications";
import toolsDataModelApi from "../../tools/toolsDataModelApi";
import OnnePicklist from "../OnnePicklist/OnnePicklist";
import useTableStates from '../App/useTableStates';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import useCollapsable from "./hooks/useCollapsable";
import usePreview from "./hooks/usePreview";
import usePicklist from "./hooks/usePicklist";
import useMapping from "./hooks/useMapping";

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "#F8F8F8",
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: "#FFFFFF",
    },
  }));

const TransitionFormula = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function QuickSearchToolbar() {
    return <GridToolbarQuickFilter onClick={(e) => e.stopPropagation()} style={{paddingTop: 12}}/>;
}

export default function OnneList({gtin}) {
    const [mapping, setMapping] = useState({});
    const [mappingBU, setMappingBU] = useState([]);
    const { collapseGroups, collapsedItems, handleCollapseChange, setCollapseGroups, setCollapsedItems } = useCollapsable();
    const { error, success } = useToolsNotifications();
    const { showPreview, showRaw } = usePreview();
    const [selectedPreview, setSelectedPreview] = useState(undefined);
    const { addMapping, deleteMapping, getSourceItems, addGpcsMapping } = toolsMappingApi();
    const { getDataModel, getMapping, getSourceDataModel, getLanguages } = toolsMappingApi();
    const { getDataModelPicklist, getPicklistRules, postPicklistRules, getPicklistRulesMapped} = toolsDataModelApi();
    const { getComponentPicklist, picklist, setPicklist, picklistConversions, setPicklistConversions, rowPicklist, setRowPicklist } = usePicklist();

    const dispatch = useDispatch();

    const gpc = useSelector((state) => state.gpc.value);
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const onneFilter = useSelector((state) => state.onneMappingFilter.value);

    const [warningCount, setWarningCount] = useState(0);
    const [showHelper, setShowHelper] = useState(false);
    const [helperDefaultId, setHelperDefaultId] = useState(undefined);
    const [showFormula, setShowFormula] = useState(false);
    const [rowFormula, setRowFormula] = useState(undefined);
    const [items, setItems] = useState(undefined);
    const [visibleItems, setVisibleItems] = useState(undefined);
    const [dataModel, setDataModel] = useState(undefined)
    const [autoMap, setAutomap] = useState(null)
    const [type, setType] = useState(undefined)
    const [version, setVersion] = useState(undefined)
    const [dataMapping, setDataMapping] = useState({});
    const [sourceFields, setSourceFields] = useState({});
    const [usedSrcFields, setUsedSrcFields] = useState([]);
    const [trascendingFields, setTrascendingFields] = useState(undefined);
    const [brickFields, setBrickFields] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [langs, setLangs] = useState([
        {name: "English" , code: "EN"},
        {name: "Spanish" , code: "ES"}
    ])
    const { states: tableStates, setStates: setTableStates } = useTableStates();

    useEffect(() => {
        let query = gtin !== undefined ? {limit: 1, gtin: gtin} : {limit: 1};
        getSourceItems(query).then((data) => {
            if(!data.error && data.payload.length > 0) {
                setSelectedPreview(data.payload[0]);
            } 
        });
    }, [])

    useEffect(() => {
        let query = gtin !== undefined ? {limit: 1, gtin: gtin} : {limit: 1};
        getSourceItems(query).then((data) => {
            if(!data.error && data.payload.length > 0) {
                setSelectedPreview(data.payload[0]);
            } else {
                setSelectedPreview(undefined);
            }
        });
    }, [gtin])
    
    useEffect(() => {
        if(visibleItems !== undefined) {
            setLoading(false);
        }
    }, [visibleItems])

    useEffect(() => {
        setLoading(true);
        if(type !== undefined && version !== undefined && gpc !== undefined) {
            getLanguages(type, version).then((data) => {
                setLangs(data.payload);
            });
            getDataModel(type, version, gpc).then((data) => {
                setDataModel(data.payload);
            });
            getSourceDataModel().then((data) => {
                setSourceFields(data.payload)
            })
            getMapping(type, version, gpc).then((data) => {
                setDataMapping(data.payload)
            });
            getDataModelPicklist(type, version, gpc).then((data) => {
                if(!data.error) {
                    let processed = {}
                    Object.keys(data.payload).map((pick) => {
                        processed[data.payload[pick][0].picklistId] = {}
                        data.payload[pick].map(elem => {
                            processed[elem.picklistId][elem.code] = elem;
                            processed[elem.picklistId][elem.code].conversion = '';
                            processed[elem.picklistId][elem.code].edited = false;
                        })
                    })
                    setPicklist(processed);
                }
            })

            getPicklistRulesMapped(type, version, gpc).then((data) => {
                if(!data.error) {
                    setPicklistConversions(data.payload);
                }
            })
        }
    },[type, version, gpc])

    useEffect(() => {
        if(selectedDataModel !== undefined) {
            setType(selectedDataModel.type);
            setVersion(selectedDataModel.version);
            console.log(selectedDataModel)
        }
    }, [selectedDataModel])

    useEffect(() => {
        if(autoMap !== null) {
            handleMappingChange(true, autoMap)
            setAutomap(null)
        }
    }, [mapping, autoMap])

    useEffect(() => {
        setMapping(mappingBU);
    }, [mappingBU])

    const buildRow = (item = undefined) => {
        if(item !== undefined) {
            let gpcs = [];
            //gpcs.brick = item.classified;
            for(var j in item.classified) {
                let gpc = {};
                gpc.brick = item.classified[j];
                for(var i in selectedDataModel.gpcs) {
                    if(selectedDataModel.gpcs[i].brick === item.classified[j]) {
                        gpc.brickName = selectedDataModel.gpcs[i].brickName
                        gpcs.push(gpc)
                        break
                    }
                }
                
            }
            
            return {
                fieldId: item.fieldId,
                mappingState: mappingBU[item.fieldId] !== undefined ? true : false,
                sourceField: mapping[item.fieldId] !== undefined ? item.fieldId : undefined,
                brick: item.classified !== undefined, 
                preview: '',
                formula: 'test1',
                gs1Attribute: ["[" + item.fieldId + "] " + item.longName],
                longName: item.longName,
                //warning: 'test',
                finish: 'test',
                mandatory: item.mandatory,
                multilingual: item.multilingual !== null ? item.multilingual : false, 
                multivalue: item.repeat !== null && item.repeat !== 0 ? true : false, 
                repeat: item.repeat !== null ? item.repeat : 0, 
                uom: item.uom,
                gpcs: gpcs,
                group : item.group,
                groupRepeat : item.groupRepeat,
                picklist: item.picklist,
            }
        } else {
            return {
                fieldId: undefined,
                mappingState: undefined,
                sourceField: undefined,
                brick: undefined,
                preview: undefined,
                formula: undefined,
                gs1Attribute: undefined,
                longName: undefined,
                //warning: 'test',
                finish: undefined,
                mandatory: undefined,
                multilingual: undefined, 
                multivalue: undefined,
                repeat: undefined,
                uom: undefined,
                gpcs: undefined,
                picklist: undefined,
            }
        }
        
    }

    const processItems = (itemsData) => {
        let processed = itemsData.map((it) => {
            return buildRow(it);

        })

        let loadedGroups = [];
        let processedGroups = processed;
        let ginserted = 0;
        let mappingCopy = {...mappingBU};
        let collapse = {...collapseGroups};
        let collItems = collapsedItems;
        
        for (let i = 0; i < processed.length; i++) {
            if(processed[i].fieldId === processed[i].group) {
                processed[i].groupItems = [];
                let groupItems =  processed.filter(function (a) {
                    return a.group === processed[i].group})
                for (let j = 0; j < groupItems.length; j++) {
                    let nItem = {...groupItems[j]}
                    nItem.rowClassName = j===0 ? 'firstItem' : j===groupItems.length-1 ? 'lastItem' : undefined;
                    nItem.child = true;
                    processed[i].groupItems.push({...nItem});
                }

                
                
                if(processed[i].groupItems !== undefined) {      
                    processed[i].rowClassName = 'groupHead';
                    processed[i].sourceField = 'Group field: ' + processed[i].fieldId;
                    collapse[processed[i].fieldId] = false;

                    for(let it = 0; it <  processed[i].groupItems.length; it++) {
                        if(processed[i].groupItems[it] !== undefined && processed[i].groupItems[it].fieldId !== undefined) {
                            if(mappingBU[processed[i].groupItems[it].fieldId] !== undefined) {
                                loadedGroups[processed[i].fieldId] = loadedGroups[processed[i].fieldId] === undefined ? [] : loadedGroups[processed[i].fieldId];
                                for(let groupPos of Object.keys(mappingBU[processed[i].groupItems[it].fieldId])) {
                                    loadedGroups[processed[i].fieldId][groupPos] = loadedGroups[processed[i].fieldId][groupPos] === undefined ? [] : loadedGroups[processed[i].fieldId][groupPos];
                                    loadedGroups[processed[i].fieldId][groupPos][processed[i].groupItems[it].fieldId] = mappingBU[processed[i].groupItems[it].fieldId][groupPos];
                                }
                            }
                        }
                    }
                    
                    if(loadedGroups[processed[i].fieldId] !== undefined) {
                        for(let groups = 1; groups <= loadedGroups[processed[i].fieldId].length; groups++) {
                            for(let giIndex in processed[i].groupItems) {
                                let newItem = buildRow(processed[i].groupItems[giIndex]);
                                let newFieldId = processed[i].groupItems[giIndex].fieldId + "_"  + groups
                                newItem.fieldId = newFieldId;
                                newItem.originalId = processed[i].groupItems[giIndex].fieldId;
                                newItem.originalGroup = processed[i].groupItems[giIndex].group;
                                newItem.group = processed[i].groupItems[giIndex].group + "_"  + groups;
                                newItem.rowClassName = processed[i].groupItems[giIndex].rowClassName;
                                newItem.gs1Attribute = "[" + processed[i].groupItems[giIndex].fieldId + "_"  + groups + "] " + processed[i].groupItems[giIndex].longName;
                                newItem.groupPosition = groups;
                                newItem.child = true;
                                newItem.sourceField = mappingBU[processed[i].groupItems[giIndex].fieldId] !== undefined ? mappingBU[processed[i].groupItems[giIndex].fieldId][groups] !== undefined ? mappingBU[processed[i].groupItems[giIndex].fieldId][groups] : undefined : undefined //mappingBU[processed[i].groupItems[giIndex].fieldId] !== undefined ? mappingBU[processed[i].groupItems[giIndex].fieldId][groups] !== undefined ? mappingBU[processed[i].groupItems[giIndex].fieldId][groups] : undefined : undefined

                                processedGroups = [
                                    ...processedGroups.slice(0, i+1+ginserted),
                                    newItem,
                                    ...processedGroups.slice(i+1+ginserted)
                                ];
                                ginserted++;
                                if(mappingBU[processed[i].groupItems[giIndex].fieldId] !== undefined) {                                    
                                    mappingCopy[newFieldId] = mappingBU[processed[i].groupItems[giIndex].fieldId][groups]
                                }

                                collItems.push(newFieldId);
                            }
                        }
                    } 
                }
            }
        }

        /*** ADD CHILDREN ROWS */

        
        let processedFinal = processedGroups
        let inserted = 0;
        

        for (let i = 0; i < processedGroups.length; i++) {
            if(mappingCopy[processedGroups[i].fieldId] !== undefined) {
                if(processedGroups[i].fieldId !== processedGroups[i].group || processedGroups[i].child === true) {
                    if(processedGroups[i].multilingual) {
                        collapse[processedGroups[i].fieldId] = false;
                        for(let lang of Object.keys(mappingCopy[processedGroups[i].fieldId])) {
                            let newFieldId = processedGroups[i].fieldId + "_"  + lang;
                            let newItem = buildRow(processedGroups[i]);
                            newItem.fieldId = newFieldId;
                            newItem.originalId = processedGroups[i].originalId !== undefined ? processedGroups[i].originalId : processedGroups[i].fieldId;
                            newItem.mappingState = true;
                            newItem.sourceField = mappingCopy[processedGroups[i].fieldId][lang];
                            newItem.gs1Attribute = "[" + newFieldId + "] " + processedGroups[i].longName;
                            newItem.multilingual = false;
                            newItem.lang = true;

                            if(processedGroups[i].child) {
                                newItem.originalGroup = processedGroups[i].originalGroup;
                                newItem.group = processedGroups[i].group;
                                newItem.rowClassName = processedGroups[i].rowClassName;
                                newItem.gs1Attribute = processedGroups[i].gs1Attribute;
                                newItem.groupPosition = processedGroups[i].groupPosition;
                                newItem.child = true;
                            }
                            processedFinal = [
                                ...processedFinal.slice(0, i+1+inserted),
                                newItem,
                                ...processedFinal.slice(i+1+inserted)
                            ];
                            inserted++;
                            mappingCopy[newFieldId] = {originalId: processedGroups[i].fieldId, value: mappingCopy[processedGroups[i].fieldId][lang].value, lang: lang}
                            collItems.push(newFieldId);
                        }
                    } else {
                        if(processedGroups[i].multivalue) {
                            collapse[processedGroups[i].fieldId] = false;
                            for(let position of Object.keys(mappingCopy[processedGroups[i].fieldId])) {
                                let intPos = parseInt(position)
                                let newFieldId = processedGroups[i].fieldId + "_"  + intPos;
                                let newItem = buildRow(processedGroups[i]);

                                newItem.fieldId = newFieldId;
                                newItem.originalId = processedGroups[i].originalId !== undefined ? processedGroups[i].originalId : processedGroups[i].fieldId;
                                newItem.position = intPos;
                                newItem.mappingState = true;
                                newItem.sourceField = mappingCopy[processedGroups[i].fieldId][position];
                                newItem.gs1Attribute = "[" + newFieldId + "] " + processedGroups[i].longName;
                                newItem.multilingual = false;
                                newItem.lang = false;
                                newItem.multivalue = false;

                                if(processedGroups[i].child) {
                                    newItem.originalGroup = processedGroups[i].originalGroup;
                                    newItem.group = processedGroups[i].group;
                                    newItem.rowClassName = processedGroups[i].rowClassName;
                                    newItem.gs1Attribute = processedGroups[i].gs1Attribute;
                                    newItem.groupPosition = processedGroups[i].groupPosition;
                                    newItem.child = true;
                                    
                                }
                                processedFinal = [
                                    ...processedFinal.slice(0, i+1+inserted),
                                    newItem,
                                    ...processedFinal.slice(i+1+inserted)
                                ];

                                inserted++;
                                let mappingValue  = mappingCopy[processedGroups[i].fieldId][position] !== undefined ? mappingCopy[processedGroups[i].fieldId][position].value : undefined;
                                let mappingUom  = mappingCopy[processedGroups[i].fieldId][position] !== undefined ? mappingCopy[processedGroups[i].fieldId][position].uom : undefined;
                                mappingCopy[newFieldId] = {
                                    originalId: processedGroups[i].fieldId, 
                                    value: mappingValue,
                                    position: intPos, 
                                    uom: mappingUom
                                }
                                collItems.push(newFieldId);
                            }
                        }
                    }
                }
            }
            
        }   

        // Process used sources
        let used = [];
        Object.keys(mappingCopy).map((key) => {
            let value = mappingCopy[key] !== undefined && mappingCopy[key] !== null ? mappingCopy[key].value !== undefined ? mappingCopy[key].value : typeof(mappingCopy[key]) !== "object" ? mappingCopy[key] : undefined : undefined;
            if(value !== undefined) {
                used.push(value)
            }
        })

        setCollapsedItems(collItems);
        setCollapseGroups(collapse);
        setUsedSrcFields(used);
        setMappingBU(mappingCopy);
        setItems(processedFinal);
    }

    const updateVisibleItems = () => {
        setLoading(true);
        let nVisibleItems = items;
        
        if(onneFilter.mappedState !== 0) {
            let target = onneFilter.mappedState === 1;
            nVisibleItems = nVisibleItems.filter(function(element) { return element.mappingState === target }); 
        }

        if(onneFilter.dataType !== 0) { //brick
            let target = onneFilter.dataType === 2;
            nVisibleItems = nVisibleItems.filter(function(element) { return element.brick === target }); 
        }

        if(onneFilter.mappedType !== 0) { //mandatory
            let target = onneFilter.mappedType === 1;
            nVisibleItems = nVisibleItems.filter(function(element) { return element.mandatory === target }); 
        }
        
        // Remove the group items not inside a defined group
        nVisibleItems = nVisibleItems.filter(function(element) { 
            return element.child === true || element.group === element.fieldId || element.group === undefined || element.group === null
        });

        nVisibleItems = nVisibleItems.filter(function(element) { 
            return !collapsedItems.includes(element.fieldId);
        });


        setVisibleItems(nVisibleItems)
    }

    //**************************** */

    const processMapping = (mappingData) => {
        let processed = {};

        Object.keys(mappingData).map((key) => {
            if(typeof mappingData[key] === "string") {
                processed[key] = {value: mappingData[key]}
            } else {
                processed[key] = mappingData[key]
            }
        })

        setMappingBU(processed);
    }

    const checkFieldsData = (dm) => {
        let trascendingFieldsLocal = [];
        let brickFieldsLocal = [];

        dm.map((elem) => {
            if(elem.classified !== undefined) {
                brickFieldsLocal.push(elem.fieldId);
            } else {
                trascendingFieldsLocal.push(elem.fieldId);
            }
        })

        setTrascendingFields(trascendingFieldsLocal);
        setBrickFields(brickFieldsLocal);
    }

    useEffect(() => {
        
        if(trascendingFields !== undefined &&  brickFields !== undefined && mappingBU !== undefined) {
            let trascendingComplete = [];
            let brickComplete = [];
            Object.keys(mappingBU).map((m) => {
                if(brickFields.includes(m)) {
                    brickComplete.push(m);
                } else {
                    if(trascendingFields.includes(m)) {
                        trascendingComplete.push(m);
                    }
                }
            })

            dispatch(setDmFieldsData({
                TrascendingTotal: trascendingFields.length,
                TrascendingComplete: trascendingComplete.length, 
                BrickTotal: brickFields.length,
                BrickComplete: brickComplete.length,
            }))
        }
    }, [trascendingFields,brickFields, mappingBU])

    useEffect(() => {
        if(dataModel !== undefined) {
            processItems(dataModel);
            checkFieldsData(dataModel);
        }
    }, [dataModel]);

    useEffect(() => {
        if(dataMapping !== undefined) {
            processMapping(dataMapping)
        }
    }, [dataMapping]);

    useEffect(() => {
        if(helperDefaultId !== undefined) {
            setShowHelper(true);
        }
    }, [helperDefaultId]);

    useEffect(() => {
        if(items !== undefined) {
            updateVisibleItems();
        }
    }, [onneFilter, items, collapsedItems]);

    useEffect(() => {
        let total = 0;
        if( dataModel !== undefined && mappingBU !== undefined) {
            total = dataModel.length - Object.keys(mappingBU).length
        }
        setWarningCount(total);
    }, [dataModel, mappingBU]);

    useEffect(() => {
        if(rowFormula === undefined && rowPicklist === undefined) {
            setShowFormula(false);
        } else {
            setShowFormula(true);
        }
    }, [rowFormula, rowPicklist])

    const showUpdateMessage = () => {
        let msg = "Conversions updated successfully";
        success(msg);
    }

    const showUpdateMessageErr = () => {
        let msg = "Conversions updated error";
        error(msg);
    }

    const showUpdateMessageServerErr = () => {
        let msg = "Server error";
        error(msg);
    }

    const handleShowHelperRow = (params) => {
        setHelperDefaultId(params.row.originalId !== undefined ? params.row.originalId : params.row.fieldId)
    }

    const handleShowHelper = () => {
        setShowHelper(true);
    }
    const handleCloseHelper = () => {
        setShowHelper(false);
        setHelperDefaultId(undefined);
    }

    const handleShowFormula = (row) => {
        setRowFormula(row)
    }

    const handleCloseFormula = () => {
        if(rowFormula !== undefined) {
            setRowFormula(undefined)
        } else {
            if(rowPicklist !== undefined) {
                setRowPicklist(undefined)
            }
        }
    }

    const showValuesLimitError = (limit) => {
        let msg = "Error adding the new value, value limit: " + limit;
        error(msg);
    }

    const showNotificationMapped = () => {
        let msg = "Mapping updated successfully";
        success(msg);
    }

    const renderCheckColumn = (row) => {
        if(row.group !== undefined && row.group !== null && row.group === row.fieldId && row.child !== true) {
            return  <IconButton onClick={() => addGroup(row)} aria-label="add value">
                        <AddCircleIcon />
                    </IconButton>
        } else {
            if(row.multivalue) {
                return  <IconButton onClick={() => addValue(row)} aria-label="add value">
                            <AddCircleIcon />
                        </IconButton>
            } else {
                if(row.multilingual) {
                    return  <IconButton onClick={() => addLanguage(row.fieldId)} aria-label="add lang">
                                <AddCircleIcon />
                            </IconButton>
                }
                 else {
                    let checkIcon = undefined
                    if(mappingBU[row.fieldId] !== undefined) {
                        //checkIcon = <Typography><SuccessIcon style={{verticalAlign: 'middle'}}/></Typography>;
                    } else {
                        if(row.mandatory) {
                            checkIcon = <Typography variant="orange"><Tooltip title="Mandatory Field"><WarningAmberIcon /></Tooltip></Typography>;
                        }
                    }
                    return checkIcon;
                }
            }
        }
    }

    const changeMapping = (row, sourceField, auto) => {
        if(Object.keys(sourceField).length === 0) {
            unmapField(row)
        } else {
            let fieldId = row.fieldId
            setPartialMapping(fieldId, sourceField)
            if(auto !== undefined && auto) {
                setAutomap(row)
            }
        }
    }

    const setPartialMapping = (fieldId, sourceField) => {
        let mappingCopy = {...mapping}

        if(sourceField === undefined || sourceField === "" || sourceField === null){
            delete mappingCopy[fieldId];
        } else {
            mappingCopy[fieldId] = sourceField;
        }

        setMapping(mappingCopy)
    }

    const setPartialMappingBu = (fieldId, sourceField) => {
        let mappingCopy = {...mappingBU}

        if(sourceField === undefined || sourceField === "" || sourceField === null || Object.keys(sourceField).length === 0){
            delete mappingCopy[fieldId];
        } else {
            mappingCopy[fieldId] = sourceField;
        }

        setMappingBU(mappingCopy)
    }

    const addGroup  = (row) => {
        let index = 0;
        let count = 0;
        let item = undefined
        let fieldId = row.fieldId
        let groupSize = row.groupItems.length;
        let collapse = {...collapseGroups};
        
        for(let i = 0; i < items.length; i++) {
            if(items[i].group !== false && items[i].group !== undefined && items[i].group !== null && items[i].group.includes(row.group)) {
                count++;
                item = items[i];
            }
            if(items[i].fieldId === row.fieldId && items[i].group === row.group){
                index = i;
            }
        }

        count = count - groupSize;
        count = count / groupSize;

        if(row.groupRepeat !== 999 && count >= row.groupRepeat) {
            showValuesLimitError(row.groupRepeat);
        } else {
            let newPos = count+1;
            let qty = 1;
            let itemsCopy = items;
            for(let i = 0; i < row.groupItems.length; i++){
                let fieldId = row.groupItems[i].fieldId;
                itemsCopy = [
                    ...itemsCopy.slice(0, index+qty),
                    {
                        fieldId: fieldId + "_"  + newPos,
                        originalId: fieldId,
                        originalGroup: row.group,
                        group: row.group + "_"  + newPos,
                        mappingState: false,
                        sourceField: /* mapping[fieldId][newPos] !== undefined ? fieldId :  */undefined,
                        brick: item.classified !== undefined, 
                        preview: '',
                        formula: 'test5',
                        gs1Attribute: "[" + fieldId+ "_"  + newPos + "] " + row.groupItems[i].longName,
                        //warning: 'test',
                        finish: 'test',
                        mandatory: item.mandatory,
                        multilingual: row.groupItems[i].multilingual,
                        multivalue: row.groupItems[i].multivalue,
                        repeat: row.groupItems[i].repeat,
                        lang: false, 
                        uom: item.uom, 
                        child: true,
                        rowClassName:row.groupItems[i].rowClassName,
                        groupPosition: newPos,
                        picklist: row.groupItems[i].picklist,
                    },
                    ...itemsCopy.slice(index+qty)
                ];
                /* if(row.groupItems[i].multilingual || row.groupItems[i].multivalue) {
                    collapse[fieldId + "_"  + newPos] = false;
                } */
                qty++;
            }

            setItems(itemsCopy);
        }
        /* setCollapseGroups(collapse); */
    }

    const addLanguage = (fieldId) => {
        let index = 0;
        let count = 0;
        let item = undefined
        for(let i = 0; i < items.length; i++) {
            if(items[i].fieldId.includes(fieldId)) {
                count++;
                index = i;
                item = items[i];
            }
        }

        let newFieldId = fieldId + "_"  + count;
        let newItem = buildRow(item);
        newItem.fieldId = newFieldId;
        newItem.originalId = item.originalId !== undefined ? item.originalId : fieldId;
        newItem.mappingState = false;
        newItem.sourceField = undefined;
        newItem.gs1Attribute = "[" + fieldId + "_"  + count + "] " + item.longName;
        newItem.multilingual = false;
        newItem.lang = true;

        if(item.child) {
            newItem.originalGroup = item.originalGroup;
            newItem.group = item.group;
            newItem.rowClassName = item.rowClassName;
            newItem.gs1Attribute = item.gs1Attribute;
            newItem.groupPosition = item.groupPosition;
            newItem.child = true;
        }
        let itemsCopy = [
            ...items.slice(0, index+1),
            newItem,
            ...items.slice(index+1)
        ];

        setItems(itemsCopy);
    }

    const addValue = (row) => {
        let index = 0;
        let count = 0;
        let item = undefined
        
        let fieldId = row.fieldId

        for(let i = 0; i < items.length; i++) {
            if(items[i].fieldId.includes(fieldId)) {
                count++;
                index = i;
                item = items[i];
            }
        }

        if(row.repeat !== 999 && count >= row.repeat) {
            showValuesLimitError(row.repeat);
        } else {
            if(item.position === undefined) {
                item.position = 0
            }
            let newPos = item.position+1;    
            let newFieldId = fieldId + "_"  + newPos;
            let newItem = buildRow(item);
            newItem.fieldId = newFieldId;
            newItem.originalId = item.originalId !== undefined ? item.originalId : fieldId;
            newItem.position = newPos;
            newItem.mappingState = false;
            newItem.sourceField = undefined;
            newItem.gs1Attribute = "[" + newFieldId + "_"  + newPos + "] " + item.longName;
            newItem.multilingual = false;
            newItem.lang = false;
            newItem.multivalue = false;

            if(item.child) {
                newItem.originalGroup = item.originalGroup;
                newItem.group = item.group;
                newItem.rowClassName = item.rowClassName;
                newItem.gs1Attribute = item.gs1Attribute;
                newItem.groupPosition = item.groupPosition;
                newItem.child = true;
            }

            let itemsCopy = [
                ...items.slice(0, index+1),
                newItem,
                ...items.slice(index+1)
            ];

            setItems(itemsCopy);
        }
    }

    const renderGs1Attributte = (params) => {
        if(params.row.lang) {
            return <Autocomplete
                        disablePortal
                        clearOnBlur
                        id="combo-box-demo"
                        isOptionEqualToValue={(option, value) => option.id == value} 
                        value={mapping[params.row.fieldId] !== undefined ? mapping[params.row.fieldId].lang !== undefined ? mapping[params.row.fieldId].lang : null : null} 
                        disabled={mappingBU[params.row.fieldId] !== undefined}
                        options={langs.map((elem,key) => (
                                    {label: elem.name + " (" + elem.code + ")", id: elem.code}
                                ))}
                        sx={{ width: 300, backgroundColor: "white" }}
                        onChange={(event, newValue) => {
                            changeMapping(params.row, {originalId: mapping[params.row.fieldId].originalId, lang: newValue !== null ? newValue.id : "", value: mapping[params.row.fieldId] !== undefined ? mapping[params.row.fieldId].value !== undefined ? mapping[params.row.fieldId].value : undefined : undefined});
                        }}
                        renderInput={(data) => 
                            <TextField {...data} 
                                onKeyDown={(event) => {
                                    event.stopPropagation();
                                }}
                                placeholder="Select languages"
                                variant="standard" 
                            />}
                    /> 
        } else {
            if(params.row.position !== undefined && params.row.position > 0) {
                return '';
            } else {
                return  <Grid container spacing={1} alignItems={"center"}>
                            <Grid item>
                                <GridActionsCellItem
                                    icon={<HelpCenterOutlinedIcon />}
                                    label="Help"
                                    onClick={() => handleShowHelperRow(params)}
                                />
                            </Grid> 
                            <Grid item alignItems={"center"}>
                                {params.row.gs1Attribute}
                            </Grid>                  
                        </Grid>
            }
        }
    }

    const addUsedSrcField = (srcField) => {
        let used = usedSrcFields;
        used.push(srcField);
        setUsedSrcFields(used);
    }

    const deleteUsedSrcField = (srcField) => {
        if(usedSrcFields.includes(srcField)) {
            let used = usedSrcFields;
            let index = used.indexOf(srcField);
            if (index > -1) { 
                used.splice(index, 1);
                setUsedSrcFields(used);
            }
        }
    }

    const renderOption = (props, option) => {
        const { label, id } = option;
        return (
            <span {...props} style={{   color: usedSrcFields.includes(label) ? "white" : '', 
                                        backgroundColor: usedSrcFields.includes(label) ? "#1eb1e0" : '' }}>
                {label}
            </span>
        );
    }

    const renderSourceFieldColumn = (row) => {
        if(dataMapping !== undefined && dataMapping !== null) {
            if(!row.multilingual && !row.multivalue && (row.group !== row.fieldId || row.child !== undefined)) {
                return  <Autocomplete
                            renderOption={(props, option) => renderOption(props, option)}
                            disablePortal
                            clearOnBlur
                            id="combo-box-demo"
                            value={mapping[row.fieldId] !== undefined ? mapping[row.fieldId].value !== undefined ? mapping[row.fieldId].value : null : null}
                            disabled={mappingBU[row.fieldId] !== undefined}
                            isOptionEqualToValue={(option, value) =>  option.value == value } 
                            options={Object.keys(sourceFields).map((key) => (
                                        sourceFields[key] !== null ? {label: key + '-' + sourceFields[key], id: key} : {label: key, id: key}
                                    ))}
                            sx={{ width: 300, backgroundColor: "white", fontSize: '15px' }}
                            onChange={(event, newValue) => {
                                if(newValue === null || newValue === "" || newValue === undefined) {
                                    changeMapping(row, undefined);
                                } else {
                                    let newObject = mapping[row.fieldId] !== undefined ? mapping[row.fieldId] : {};
                                    newObject.value = newValue !== null ? newValue.id : ""
                                    changeMapping(row, newObject, !row.lang);
                                }

                                //changeMapping(row.fieldId, {lang: mapping[row.fieldId] !== undefined ? mapping[row.fieldId].lang !== undefined ? mapping[row.fieldId].lang : undefined : undefined, value: newValue !== null ? newValue.id : "", originalId: row.originalId});
                            }}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    onKeyDown={(event) => {
                                        event.stopPropagation();
                                    }}
                                    placeholder="Search"
                                    variant="standard" 
                                    sx={{ fontSize: '15px' }}
                                />}
                        />
            } else {
                if(row.fieldId === row.group && (row.child === false || row.child === undefined )) {
                    return <Box>{row.sourceField}</Box>
                }
                return '';
            }
        }
        
    }

    const buildMapBody = (row, gpcs) => {
        let fieldId = row.fieldId
        let sourceField = mapping[fieldId]
        let body = {sourceField: sourceField, fieldId: fieldId}
        if(row.child) {
            body = {sourceField: sourceField, fieldId:  row.originalId, groupId: row.originalGroup, groupPosition: row.groupPosition}
            if(row.lang !== undefined && row.lang) {
                body = {sourceField: sourceField, fieldId : row.originalId, lang: mapping[fieldId].lang, uom: mapping[fieldId].uom, groupId: row.originalGroup, groupPosition: row.groupPosition}
            } else {
                if(row.position !== undefined) {
                    body = {sourceField: sourceField, fieldId : row.originalId, position: row.position, uom: mapping[fieldId].uom, groupId: row.originalGroup, groupPosition: row.groupPosition}
                }
            }
        } else {
            if(row.lang !== undefined && row.lang) {
                body = {sourceField: sourceField, fieldId : row.originalId, lang: mapping[fieldId].lang, uom: mapping[fieldId].uom}
            } else {
                if(row.position !== undefined) {
                    body = {sourceField: sourceField, fieldId : row.originalId, position: row.position, uom: mapping[fieldId].uom}
                }
            }
        }

        if(gpcs !== undefined) {
            body.gpcs = gpcs;
        }

        return body;
    }

    const buildUnmapBody = (row) => {
        let fieldId = row.fieldId
        let body = {fieldId : fieldId}
        if(row.child) {
            body = {fieldId:  row.originalId, groupId: row.originalGroup, groupPosition: row.groupPosition}
            if(row.lang !== undefined && row.lang) {
                body = {fieldId : row.originalId, lang: row.lang, groupId: row.originalGroup, groupPosition: row.groupPosition}
            } else {
                if(mapping[fieldId].position !== undefined) {
                    body = {fieldId : row.originalId, position: row.position, groupId: row.originalGroup, groupPosition: row.groupPosition}
                } 
            }
        } else {
            if(row.lang !== undefined && row.lang) {
                body = {fieldId : row.originalId, lang: row.lang}
            } else {
                if(row.position !== undefined) {
                    body = {fieldId : row.originalId, position: row.position}
                } 
            }
        }

        return body;
    }

    const mapField = (row, gpcs) => {
        let fieldId = row.fieldId
        let body = buildMapBody(row, gpcs) 
        if(gpcs === undefined) {
            addMapping(type, version, row.gpcs !== undefined ? gpc : undefined, body).then((data) => {
                showNotificationMapped();
                addUsedSrcField(mapping[fieldId].value !== undefined ? mapping[fieldId].value : mapping[fieldId])
                setPartialMappingBu(fieldId, mapping[fieldId])
    
                if(!showFormula) {
                    let row = {}
                    for(var i in items) {
                        if(items[i].fieldId === fieldId) {
                            row = items[i]
                            break
                        }
                    }
                    if(row.uom || row.gpcs !== undefined) {
                        setRowFormula(row)
                    }
                }
            });
        } else {
            addGpcsMapping(type, version, row.fieldId, body).then((data) => {
                if(!data.error) {
                    showNotificationMapped();
                } else {
                    error("Unexpected error mapping")
                }
            });
        }

    }

    const unmapField = (row, bulk) => {
        let fieldId = row.fieldId
        let body = buildUnmapBody(row)
        deleteMapping(type, version, bulk === true || row.gpcs === undefined ? undefined : gpc, body).then((data) => {
            showNotificationMapped();
            deleteUsedSrcField(mapping[fieldId].value !== undefined ? mapping[fieldId].value : mapping[fieldId])
            setPartialMappingBu(fieldId, "");
        });
    }

    // HANDLERS    
    const handleMappingChange = (value, row) => {
        if (value !== null) {
            if(value) {
                mapField(row)
            } else {
                unmapField(row)
            }
        }
    };

    const renderMapColumn = (row) => {
        if(!row.multilingual && !row.multivalue  && (row.group !== row.fieldId || row.child !== undefined)) {
            if(mapping[row.fieldId] !== undefined){
                return <Switch style={{color:"primary"}} checked={mappingBU[row.fieldId] !== undefined} onChange={(e) => handleMappingChange(e.target.checked, row)} />
            } else {
                return <Switch style={{color:"primary"}} disabled checked={mappingBU[row.fieldId] !== undefined} onChange={(e) => handleMappingChange(e.target.checked, row)} />
            }
        }

        return '';
    }

    const handleDeleteRow = (fieldId) => {
        setPartialMapping(fieldId, '');
        setItems(items =>
            items.filter(item => {
                return item.fieldId !== fieldId;
            }),
        );
    }
    
    const showActionFormula = (row) => {
        let active= { color: '#1EB1E0', fontWeight: '700' }

        if(row.lang || (row.position !== undefined && row.position !== 0)) {
            return  <Box>
                        <GridActionsCellItem
                            icon={<CreateOutlinedIcon />}
                            label="Formula"
                            onClick={() => handleShowFormula(row)}
                        />
                        <GridActionsCellItem
                            icon={<DeleteOutlineIcon />}
                            label="Remove"
                            disabled={mappingBU[row.fieldId] !== undefined}
                            onClick={() => handleDeleteRow(row.fieldId)}
                        />
                    </Box>
        } else {
            if(row.fieldId !== row.group || row.child === true) {
                return  <Box>
                            <GridActionsCellItem
                                icon={<CreateOutlinedIcon sx={mappingBU[row.fieldId] !== undefined && mappingBU[row.fieldId].default !== undefined ? active : undefined} />}
                                label="Formula"
                                onClick={() => handleShowFormula(row)}
                            />
                        </Box>
            }
            return <Box></Box>;
        }
    }

    const showPicklist = (row) => {
        let component = getComponentPicklist(row, mapping, selectedPreview);
        if(component === '') {
            component = showPreview(row, mapping, selectedPreview);
        }

        return component;
    }

    const getRowClass = (params) => {
        let className = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        if(params.row.ClassName !== undefined) {
            if(params.row.ClassName === 'groupHead') {
                var countfiltered = items.filter(function(element){
                    className = element.originalGroup === params.row.group && element.fieldId !== params.row.fieldId;
                }).length

                if(countfiltered > 0){ 
                    className = params.row.ClassName
                }
            } else {
                className = params.row.ClassName
            }
        } else {
            if(params.row.position !== undefined || params.row.lang || params.row.child) {
                className = `childRow`
            } else {
                if( mappingBU[params.row.fieldId] === undefined && params.row.mandatory) {
                    className = `errorRow`
                }
            }
        }

        return className;
    }

    const renderCollapse = (row) => {
        if(collapseGroups[row.fieldId] === true) {
            return  <IconButton onClick={() => handleCollapseChange(row, items)} aria-label="add value">
                        <UnfoldLessIcon />
                    </IconButton>
        } else {
            if(collapseGroups[row.fieldId] === false) {
                return  <IconButton onClick={() => handleCollapseChange(row, items)} aria-label="add value">
                            <UnfoldMoreIcon />
                        </IconButton>
            }
        }

        return '';
    }
       
    const columns = [
        {
            field: 'check',
            headerName: 'Check',
            align: 'center',
            renderHeader: () => (
                <Typography variant="selected">Check</Typography>
            ),
            renderCell: (params) => renderCheckColumn(params.row),
        },
        {
            field: 'gs1Attribute',
            align: 'left',
            width: 500,
            minWidth: 450,
            renderHeader: () => (
                <Grid container columnSpacing={2} justifyItems={"center"}>
                    <Grid item><Typography variant="selected">Target Attribute</Typography></Grid>
                    <Grid item>
                        <QuickSearchToolbar  />
                    </Grid>
                </Grid>
            ),
            renderCell: (params) => (renderGs1Attributte(params))
        },
        {
            field: 'collapse',
            headerName: 'Check',
            align: 'center',
            renderHeader: () => (
                <Typography variant="selected">V</Typography>
            ),
            renderCell: (params) => renderCollapse(params.row),
        },
        {
            field: 'fieldId',
            headerName: 'Field Id',
            align: 'left',
            hide: true,
        },
        {
            field: 'dataType',
            headerName: 'Data Type',
            align: 'left',
            hide: true,
        },
        {
            field: 'sourceField',
            headerName: 'Source Field',
            align: 'left',
            minWidth: 320,
            renderHeader: () => (
                <Typography variant="selected">Source Field</Typography>
            ),
            renderCell: (params) => renderSourceFieldColumn(params.row)
        },
        {
            field: 'map',
            headerName: 'Map',
            align: 'left',
            renderHeader: () => (
                <Typography variant="selected">Map</Typography>
            ),
            renderCell: (params) => renderMapColumn(params.row)
        },
        {
            field: 'formula',
            headerName: 'Formula',
            align: 'left',
            sortable: false,
            type: 'actions',
            sortable: false,
            width: 100,
            renderHeader: () => (
                <Typography variant="selected">Formula</Typography>
            ),
            getActions: (params) => [
                showActionFormula(params.row),
            ],
        },
        {
            field: 'renderSource',
            headerName: 'Source',
            align: 'left',
            width: 150,
            sortable: false,
            cellClassName: 'previewCell',
            headerClassName: 'previewHeader',
            renderHeader: () => (
                <Box sx={{lineHeight: "5px"}}><Typography variant="selected">Source</Typography></Box>
            ),
            renderCell: (params) => (showRaw(params.row, mapping, selectedPreview))
        },
        {
            field: 'renderPreview',
            headerName: 'Preview',
            align: 'left',
            width: 250,
            sortable: false,
            cellClassName: 'previewCell',
            headerClassName: 'previewHeader',
            renderHeader: () => (
                <Box sx={{lineHeight: "5px"}}><Typography variant="selected">Preview</Typography></Box>
            ),
            renderCell: (params) => (showPicklist(params.row))
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            hide: true,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<HelpCenterOutlinedIcon />}
                    label="Help"
                    onClick={() => handleShowHelperRow(params)}
                />,
                <GridActionsCellItem
                    icon={params.row.mandatory ? 
                            <Tooltip title="Mandatory Field" placement="bottom"><ReportGmailerrorredIcon color="red" /></Tooltip> 
                        : 
                            <ReportGmailerrorredIcon />
                        }    
                //icon={<ReportGmailerrorredIcon color={params.row.mandatory ? "red" : "grey"}/>}
                    label="Toggle Admin"
                />,
            ],
            width: 200,
            renderHeader: () => (
                <Button variant="onneBlue" fullWidth onClick={() => handleShowHelper()}>Need help?</Button>
            ),
        },
        /* {
            field: 'picklist',
            headerName: 'Picklist',
            align: 'left',
            width: 150,
            sortable: false,
            cellClassName: 'previewCell',
            headerClassName: 'previewHeader',
            renderHeader: () => (
                <Box sx={{lineHeight: "5px"}}><Typography variant="selected">Picklist</Typography></Box>
            ),
            renderCell: (params) => (showPicklist(params.row))
        }, */
    ]

    const [colOrder, setColOrder] = useState([])
    
    useEffect(() => {
        if(tableStates !== undefined && tableStates['mapping'] !== undefined ) {
            setColOrder(tableStates['mapping'])
        }
    }, [])
    

    useEffect(() => {
        if(colOrder.length > 0) {
            let ts = tableStates;
            ts['mapping'] = colOrder;
            setTableStates(ts)
        }
    }, [colOrder])

    return  <Grid item container width="100%" height="100%" > 
                <StripedDataGrid
                    initialState={{
                        columns: {
                            orderedFields: ['check','fieldId','dataType','sourceField','map','formula','gs1Attribute','renderSource','renderPreview','actions']
                        }
                    }}
                    state={{
                        columns: {
                            orderedFields: colOrder
                        }
                    }}
                    onColumnOrderChange={(columnOrder) => {
                            console.log(colOrder)
                            const {field, targetIndex} = columnOrder;
                            let colCopy = colOrder;
                            var index = colCopy.indexOf(field);
                            if (index !== -1) {
                                colCopy.splice(index, 1);
                            }
                            colCopy.splice(targetIndex, 0, field);
                            console.log(colCopy)
                            setColOrder(colCopy); // save the state of columns that were reordered in an array
                        }
                    }
                    sx={{
                        ' & .groupHead' : {
                            borderBottom: "2px solid #000",
                        },
                        ' & .firstItem' : {
                            background: "#c6ebf7"
                        },
                        ' & .lastItem' : {
                            borderBottom: "2px solid #000",
                            background: "#c6ebf7"
                        },
                        '& .childRow': {
                            background: "#c6ebf7"
                        },
                        '& .errorRow' : {
                            background: "rgba(225, 108, 67, 0.1)"
                        },
                        '& .previewCell': {
                            backgroundColor: "#F2F2F2",
                        },
                        '& .previewHeader .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitleContainerContent': {
                            height: '100%',
                          },
                        fontFamily: "Roboto" }}
                    rows={visibleItems !== undefined ? visibleItems : []}
                    columns={columns}
                    disableSelectionOnClick
                    getRowId={(r) => r.fieldId}
                    loading={loading}
                    getRowClassName={(params) => getRowClass(params)}
                />
                <Drawer
                    anchor={"right"}
                    open={showHelper}
                    onClose={handleCloseHelper}
                    PaperProps={{
                        sx: {
                            top: '25%',
                            height: '50%',
                        }
                      }}
                >
                    <OnneHelper dataModel={dataModel} type={type}  version={version} defaultId={helperDefaultId} />
                </Drawer>
                {mappingBU !== undefined && (rowFormula !== undefined || rowPicklist  !== undefined)? 
                    <Dialog
                        open={showFormula}
                        TransitionComponent={TransitionFormula}
                        keepMounted
                        onClose={handleCloseFormula}
                        aria-describedby="alert-dialog-slide-description"
                        maxWidth={rowFormula !== undefined ? rowFormula.gpcs !== undefined ? 800 : 600 : 300}
                        fullWidth
                    >
                        <DialogTitle>{rowFormula !== undefined ? "Formula" : "Picklist"}</DialogTitle>
                        <DialogContent>
                            {rowFormula !== undefined ?
                                <OnneFormula 
                                    field={rowFormula} 
                                    changeMapping={changeMapping} 
                                    sourceFields={sourceFields} 
                                    renderOption={renderOption}
                                    mapping={mapping[rowFormula.fieldId]}
                                    mapField={mapField}
                                    unmapField={unmapField}
                                />
                            :
                                rowPicklist !== undefined ?
                                    <OnnePicklist 
                                        field={rowPicklist} 
                                        picklist={picklist}
                                        setConversions={setPicklistConversions}
                                        setPicklist={setPicklist}
                                        changeMapping={changeMapping} 
                                        conversions={picklistConversions[rowPicklist.picklist]}
                                    />
                                :
                                    ''
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseFormula}>Ok</Button>
                        </DialogActions>
                    </Dialog>
                :
                    ''
                }
            </Grid>;
}   